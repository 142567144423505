// import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'

export default {
  // generatePDF: ((content, paperOrientation = 'p', paperSize = 'A4', fileName = null) => {
  //   // console.log(content)
  //   // eslint-disable-next-line new-cap
  //   const pdf = new jsPDF(paperOrientation, 'pt', paperSize)
  //   //   const content = document.getElementById('pdfArea')
  //   content.classList.remove('d-none')
  //   const width = content.offsetWidth // Get dom width
  //   const height = content.offsetHeight // Get dom height

  //   const canvas = document.createElement('canvas') // Create a canvas node
  //   const scale = 1 // Define any magnification to support decimals
  //   canvas.width = width * scale
  //   canvas.height = height * scale
  //   canvas.getContext('2d').scale(scale, scale) // Get context, set scale
  //   const opts = {
  //     scale, // added scale parameter
  //     canvas, // Custom canvas
  //     logging: true, // log switch, easy to view the internal execution process of html2canvas
  //     width, // dom original width
  //     height,
  //     useCORS: true, // [Important] Enable cross-domain configuration
  //   }
  //   html2canvas(content, opts).then(canvasData => {
  //     const context = canvasData.getContext('2d')
  //     // [Important] Turn off anti-aliasing
  //     context.mozImageSmoothingEnabled = false
  //     context.webkitImageSmoothingEnabled = false
  //     context.msImageSmoothingEnabled = false
  //     context.imageSmoothingEnabled = false

  //     // const contentWidth = canvasData.width
  //     // const contentHeight = canvasData.height
  //     // console.log('contentWidth', contentWidth)
  //     // console.log('contentHeight', contentHeight)
  //     // const imgData = canvasData.toDataURL()
  //     // const imgWidth = 841.89
  //     // // eslint-disable-next-line no-mixed-operators
  //     // const pageHeight = canvasData.height / 592.28 * 841.89
  //     // // eslint-disable-next-line no-mixed-operators
  //     // const imgHeight = canvasData.height * imgWidth / canvasData.width
  //     // let heightLeft = pageHeight
  //     // let position = 0

  //     // pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
  //     // heightLeft -= imgWidth

  //     // while (heightLeft >= 0) {
  //     //   position = heightLeft - imgHeight
  //     //   pdf.addPage()
  //     //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
  //     //   heightLeft -= pageHeight
  //     // }
  //     // window.open(pdf.output('bloburl'), '_blank')
  //     // doc.save( 'file.pdf');`

  //     const dataUrl = canvasData.toDataURL()
  //     //   console.log(dataUrl)
  //     pdf.addImage(dataUrl, 'JPEG', 10, 10)
  //     //   doc.save('filename.pdf')
  //     window.open(pdf.output('bloburl'), '_blank')
  //     console.log(fileName)
  //   })
  // }),
}
