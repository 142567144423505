<template>
  <section>
    <b-card>
      <div class="d-flex justify-content-between">
        <p class="card-title">
          Paid Report
        </p>
      </div>
      <!--Campus, School, Class, Shift-->
      <div class="custom-search d-flex">
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <v-select
            v-model="campusFilter"
            label="name"
            placeholder="All Campus"
            :options="campusOptions"
            :reduce="(val) => val.id"
          />
        </b-form-group>
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <v-select
            v-model="schoolFilter"
            label="name"
            placeholder="All School"
            :options="schoolOptions"
            :reduce="(val) => val.id"
            @input="changeSchool"
          />
        </b-form-group>
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <v-select
            v-model="classFilter"
            label="name"
            placeholder="All Class"
            :options="classOptions"
            :reduce="(val) => val.id"
            :disabled="classOptions.length === 0"
            @input="changeClass"
          />
        </b-form-group>
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <v-select
            v-model="sessionId"
            label="name"
            placeholder="All Session"
            :options="sessionOptions"
            :reduce="(val) => val.id"
            :disabled="sessionOptions.length === 0"
            @input="changeSession"
          />
        </b-form-group>
      </div>
      <!--Section, From Date, To date, All Bank-->
      <div class="custom-search d-flex">
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <v-select
            v-model="shiftFilter"
            label="name"
            placeholder="All Shift"
            :options="shiftOptions"
            :reduce="(val) => val.id"
            :disabled="hasShift === 0"
            @input="changeShift"
          />
        </b-form-group>
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <v-select
            v-model="sectionFilter"
            label="name"
            placeholder="All Section"
            :options="sectionOptions"
            :reduce="(val) => val.section_id"
            :disabled="sectionOptions.length === 0"
          />
        </b-form-group>
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <v-select
            v-model="bankFilter"
            label="gl_name"
            placeholder="All Bank"
            :options="bankOptions"
            :reduce="(val) => val.coa_id"
          />
        </b-form-group>
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <flat-pickr
            v-model="fromDateTime"
            placeholder="From Date/Time"
            class="form-control"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
              defaultDate: 'today',
            }"
            required
          />
        </b-form-group>
      </div>
      <!-- Submit Button-->
      <div class="custom-search d-flex">
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <flat-pickr
            v-model="toDateTime"
            placeholder="To Date/Time"
            class="form-control"
            :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
            required
          />
        </b-form-group>
        <b-form-group class="pr-0 col-lg-3 col-md-3">
          <b-button
            type="submit"
            variant="outline-success"
            class="mr-1"
            @click.prevent="submitButton"
          >
            Search
          </b-button>
          <b-button
            v-if="isPrintButton && isShow && rows.length > 0"
            class="ml-1 px-1"
            variant="outline-primary"
            @click.prevent="printList"
          >
            Print
          </b-button>
        </b-form-group>
      </div>

      <div
        v-if="isShow && rows.length > 0"
        class="vueTable"
      >
        <div class="text-center mb-1">
          <h2>Paid Report</h2>
          ( <b>From:</b> {{ dateFrom }} - <b>To:</b> {{ dateTo }} )
        </div>
        <div style="margin-bottom: 5px">
          <b>Total Data: {{ totalRow }}</b>
        </div>
        <table
          id="printTable"
          class="pageContent table table-hover table-bordered"
        >
          <thead>
            <tr class="left-align">
              <th
                width="50px"
                class="left-padding"
              >
                SL.
              </th>
              <th
                width="280px"
                class="left-align"
              >
                Student Name
              </th>

              <th
                width="360px"
                class="left-align"
              >
                Class - Shift - Section
              </th>
              <th
                width="50px"
                class="left-padding"
              >
                Roll
              </th>
              <th
                width="220px"
                class="left-align"
              >
                Bank Name
              </th>
              <th
                width="190"
                class="right-align"
              >
                Paid Amount
              </th>
              <th
                width="240px"
                class="left-align"
              >
                Payment Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in rows"
              :key="item.id"
              class="left-align"
            >
              <td class="left-align">
                {{ ++index }}
              </td>
              <td class="left-align">
                {{ item.name }} ({{ item.record_id }})
              </td>

              <td class="left-align">
                {{ item.class }}-{{
                  item.shift_id == 1
                    ? "Morning"
                    : item.shift_id
                      ? "Day"
                      : "N/A"
                }}-{{ item.section }}
              </td>
              <td class="left-align">
                {{ item.roll_no }}
              </td>
              <td class="left-align">
                {{ item.gl_name }}
              </td>
              <td class="right-align">
                {{ item.amount.toFixed(2) }}
              </td>
              <td class="left-align">
                {{ item.created_at }}
              </td>
            </tr>
            <tr>
              <td
                class="right-align"
                colspan="4"
              >
                <b>Total Amount</b>
              </td>
              <td class="right-align">
                <b>{{ totalAmount }}<b /></b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <b-spinner
          v-if="isLoading"
          variant="primary"
        />
      </div>
      <PaidPdf
        v-if="isPrintDocument"
        ref="docRef"
        :document-data="documentData"
        :total-amount="totalAmount"
      />
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import PaidReportsService from '@/@service/api/accounting/PaidReports.service'
import PaidPdf from '@/views/reports/components/PaidPdf.vue'
// eslint-disable-next-line no-unused-vars
import {
  showLocalDateTime,
  showLocalDateTimeDBFormat,
  showOnlyDateDBFormat,
} from '@/@service/utils/utils'

export default {
  components: {
    BCard,
    vSelect,
    BFormGroup,
    flatPickr,
    BButton,
    BSpinner,
    PaidPdf,

  },
  data() {
    return {
      pageLength: 50,

      campusFilter: null,
      campusOptions: [],
      schoolFilter: null,
      schoolOptions: [],
      classFilter: null,
      sessionId: null,
      classOptions: [],
      hasShift: 0,
      shiftFilter: null,
      shiftOptions: [{
        id: 1,
        name: 'Morning',
      },
      {
        id: 2,
        name: 'Day',
      },
      ],
      sectionFilter: null,
      sessionOptions: [],
      sectionOptions: [],
      fromDateTime: null,
      toDateTime: new Date(),
      bankFilter: null,
      bankOptions: [],
      rows: [],
      isShow: false,
      isPrintButton: false,
      isPrintDocument: false,
      documentData: {},

      dateFrom: null,
      dateTo: null,
      totalRow: 0,
      isLoading: false,
      totalAmount: 0.00,

    }
  },
  watch: {
    schoolFilter(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.classFilter = null
      }
    },
    classFilter(oldVal, newVal) {
      if (oldVal !== newVal) {
        this.shiftFilter = null
        this.sectionFilter = null
        this.sectionOptions = []
      }
    },
  },
  mounted() {
    this.getAllCampuses()
    this.getAllSchools()
    this.getAllClass()
    this.getAllBanks()
  },
  methods: {
    getAllCampuses() {
      PaidReportsService.getAllCampuses()
        .then(res => {
          if (res.data.status) {
            this.campusOptions = res.data.data
          } else {
            this.campusOptions = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    getAllSchools() {
      PaidReportsService.getAllSchools()
        .then(res => {
          if (res.data.status) {
            this.schoolOptions = res.data.data
          } else {
            this.schoolOptions = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    changeSchool(val) {
      if (val === null) {
        this.hasShift = 0
        this.classFilter = null
        this.shiftFilter = null
        this.sectionFilter = null
        this.sectionOptions = []
        this.getAllClass()
        return
      }
      PaidReportsService.getClassesBySchoolId(val)
        .then(res => {
          if (res.data.status) {
            this.classOptions = res.data.data
          } else {
            this.classOptions = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    changeClass(val) {
      this.hasShift = 0
      this.shiftFilter = null
      this.sessionId = null
      this.sessionOptions = []
      this.sectionFilter = null
      this.sectionOptions = []
      if (val === null) {
        return
      }
      this.getAllSession(val)
      this.hasShift = this.classOptions.find(x => Number(val) === Number(x.id)).has_shift
      if (this.hasShift === 0) {
        this.shiftFilter = null
      } else {
        this.sectionFilter = null
        this.sectionOptions = []
      }
    },

    getSection() {
      PaidReportsService.getAllSection(this.classFilter, this.shiftFilter || 0, this.sessionId)
        .then(res => {
          if (res.data.status) {
            this.sectionOptions = res.data.data
          } else {
            this.sectionOptions = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    getAllClass() {
      PaidReportsService.getAllClasses()
        .then(res => {
          if (res.data.status) {
            this.classOptions = res.data.data
          } else {
            this.classOptions = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },

    getAllSession(id) {
      PaidReportsService.getAllSession(id).then(res => {
        this.sessionOptions = res.data.data
      })
    },
    changeSession() {
      this.sectionFilter = null
      this.sectionOptions = []
      this.getSection()
    },
    changeShift(val) {
      PaidReportsService.getAllSection(this.classFilter, val)
        .then(res => {
          if (res.data.status) {
            this.sectionOptions = res.data.data
          } else {
            this.sectionOptions = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    getAllBanks() {
      PaidReportsService.getAllBanks()
        .then(res => {
          if (res.data.status) {
            this.bankOptions = res.data.data
          } else {
            this.bankOptions = []
          }
        })
        .catch(err => {
          FemsToastrService.error(err?.message)
        })
    },
    submitButton() {
      this.isLoading = true
      this.documentData = {}
      this.rows = []
      const from = this.fromDateTime ? showLocalDateTimeDBFormat(this.fromDateTime) : showOnlyDateDBFormat(new Date())
      const to = this.toDateTime ? showLocalDateTimeDBFormat(this.toDateTime) : showLocalDateTimeDBFormat(new Date())

      if (Date(to) < Date(from)) {
        this.isLoading = false
        FemsToastrService.error('From Date cannot be greater than To Date')
        return
      }

      const params = {
        campus_id: this.campusFilter || null,
        school_id: this.schoolFilter || null,
        class_id: this.classFilter || null,
        session_id: this.sessionId || null,
        shift_id: this.shiftFilter || null,
        section_id: this.sectionFilter || null,
        collection_coa_id: this.bankFilter || null,
        fromDateTime: from,
        toDateTime: to,
      }

      this.getGridData(params)
    },

    getGridData(params) {
      this.isShow = true
      this.isPrintButton = true
      PaidReportsService.getPaidReport(params)
        .then(res => {
          if (res.data.status) {
            this.isLoading = false
            this.documentData = res.data
            this.documentData = {
              ...this.documentData,
              heading: {
                from_date: showLocalDateTime(this.fromDateTime),
                to_date: showLocalDateTime(this.toDateTime),
              },
            }
            this.rows = res.data.data
            this.dateFrom = showLocalDateTime(this.fromDateTime)
            this.dateTo = showLocalDateTime(this.toDateTime)
            this.totalRow = res.data.total
            let total = 0
            this.rows.forEach(item => { total += item.amount })
            this.totalAmount = total.toFixed(2)
          } else {
            this.isLoading = false
            FemsToastrService.error(res?.data.message)
            this.rows = []
            this.documentData = []
          }
        })
        .catch(err => {
          this.isLoading = false
          FemsToastrService.error(err?.message)
          this.rows = []
          this.documentData = []
        })
    },
    printList() {
      if (!this.rows.length) {
        FemsToastrService.error('No data found')
        return
      }
      this.isPrintDocument = true
      this.$nextTick(() => {
        this.$refs.docRef.printDocument()
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.left-align {
  padding-left: 10px;
}

.right-align {
  text-align: right;
  padding-right: 20px;
}

.table-hover tbody tr {
  cursor: text !important;
}

/* .pdfView{
  display: none;
} */
</style>
